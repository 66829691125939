@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif, monospace;
}

@layer base {
  html {
    font-family: 'Inter', system-ui, sans-serif;
  }
}
